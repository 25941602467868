import axios from 'axios';
import { Product, Filter, FilterCategory, StrapiResponse } from '@/types';
import { getImageUrls } from '@/backend/strapi/apiService';

const API_URL = 'https://solido-herrajes-strapi.fly.dev/api/';
const apiToken = import.meta.env.VITE_STRAPI_FRONTEND_PUBLIC_ACCESS_API_TOKEN;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: 'Bearer ' + apiToken,
  },
});

export const fetchProductsBySearch = async (
  searchQuery: string
): Promise<{
  products: Product[];
  filters: Filter[];
  filterCategories: FilterCategory[];
}> => {
  try {
    // Split the search query into terms
    const terms = searchQuery.split(/\s+/).filter((term) => term.length > 0);

    // Build simplified filters
    const filters = {
      [`filters[$or][0][name][$containsi]`]: terms[0] || '',
      [`filters[$or][1][model][$containsi]`]: terms[0] || '',
      [`filters[$or][3][category][name][$containsi]`]: terms[0] || '',
      [`filters[$or][4][subcategory][name][$containsi]`]: terms[0] || '',
      [`filters[$or][5][item][name][$containsi]`]: terms[0] || '',
    };

    // Fetch products
    const response = await axiosInstance.get<StrapiResponse<Product>>(
      '/products',
      {
        params: {
          ...filters,
          populate: {
            filters: {
              populate: '*',
            },
            filter_categories: {
              populate: '*',
            },
            image: {
              populate: '*',
            },
          },
        },
      }
    );

    // Process products and related data
    const products = await Promise.all(
      response.data.data.map(async (product) => ({
        ...product,
        attributes: {
          ...product.attributes,
          image: { data: product.attributes.image?.data || null },
        },
        imageUrls: await getImageUrls(product.attributes.image?.data?.[0]),
      }))
    );

    const filtersFromProducts: Filter[] = [];
    const filterCategories: FilterCategory[] = [];

    products.forEach((product) => {
      const productFilters = product.attributes.filters?.data || [];
      const productFilterCategories =
        product.attributes.filter_categories?.data || [];

      productFilters.forEach((filter: Filter) => {
        if (!filtersFromProducts.some((f) => f.id === filter.id)) {
          filtersFromProducts.push(filter);
        }
      });

      productFilterCategories.forEach((filterCategory: FilterCategory) => {
        if (!filterCategories.some((fc) => fc.id === filterCategory.id)) {
          filterCategories.push(filterCategory);
        }
      });
    });

    return {
      products,
      filters: filtersFromProducts,
      filterCategories: filterCategories,
    };
  } catch (error) {
    console.error('Error fetching products by search:', error);
    throw error;
  }
};
