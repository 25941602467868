import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import HeaderShop from '@/components/HeaderShop';
import BodyShop from '@/components/BodyShop';
import ProductPage from './ProductPage';
import { isLargeScreen } from '@/utils/screenSizeUtils';
import {
  Product as ProductTypes,
  Filter as FilterTypes,
  FilterCategory,
} from '@/types';
import {
  fetchProductsByItem,
  fetchProductsByFilter,
  fetchProductsByCategory,
  fetchProductsBySubcategory,
} from '@/backend/strapi/apiService';
import { fetchProductsBySearch } from '@/utils/SearchUtils';
import StorePageLanding from '@/components/StorePageLanding';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { generarEtiquetasSeoMeta } from '@/site-config';

export default function Store() {
  const [selectedFilter, setSelectedFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [largeScreen, setLargeScreen] = useState(isLargeScreen());
  const location = useLocation();
  const [products, setProducts] = useState<ProductTypes[]>([]);
  const [filterCategoriesFromSearch, setFilterCategoriesFromSearch] = useState<
    FilterCategory[] | null
  >(null);
  const [filtersFromSearch, setFiltersFromSearch] = useState<
    FilterTypes[] | null
  >(null);
  const [productPage, setProductPage] = useState(false);
  const initialLoad = useRef(true);
  const [loading, setLoading] = useState(false);
  const seoConfig = generarEtiquetasSeoMeta();

  const formatName = (item: string): string => {
    return item
      .split('-')
      .map((word, index) =>
        index === 0
          ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          : word.toLowerCase()
      )
      .join(' ');
  };

  useEffect(() => {
    const handleResize = () => setLargeScreen(isLargeScreen());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/tienda/producto/')) {
      setProductPage(true);
    } else if (location.pathname.includes('/tienda')) {
      setProductPage(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get('q');

    if (queryParam) {
      setSearchQuery(queryParam);
      setSelectedFilter('');
    }
  }, [location.search]);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        let fetchedProducts: ProductTypes[] = [];

        if (searchQuery) {
          if (selectedFilter) {
            fetchedProducts = await fetchProductsByFilter(selectedFilter);
          } else {
            const searchData = await fetchProductsBySearch(searchQuery);
            fetchedProducts = searchData.products;
            setFilterCategoriesFromSearch(searchData.filterCategories);
            setFiltersFromSearch(searchData.filters);
          }
        } else {
          if (location.pathname.includes('/busqueda')) {
            const productFromUrl = location.pathname.split('/').pop();
            if (productFromUrl) {
              const searchData = await fetchProductsBySearch(productFromUrl);
              fetchedProducts = searchData.products;
            }
          } else if (selectedFilter) {
            fetchedProducts = await fetchProductsByFilter(selectedFilter);
          } else {
            const pathSegments = location.pathname.split('/').filter(Boolean);
            const category =
              pathSegments.length > 1 ? formatName(pathSegments[1]) : null;
            const subcategory =
              pathSegments.length > 2 ? formatName(pathSegments[2]) : null;
            const item =
              pathSegments.length > 3 ? formatName(pathSegments[3]) : null;

            if (item) {
              fetchedProducts = await fetchProductsByItem(item);
            } else if (subcategory) {
              fetchedProducts = await fetchProductsBySubcategory(subcategory);
            } else if (category) {
              fetchedProducts = await fetchProductsByCategory(category);
            }
          }
        }

        setProducts(fetchedProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    if (initialLoad.current) {
      initialLoad.current = false;
      fetchProducts();
    } else {
      fetchProducts();
    }
  }, [location.pathname, selectedFilter, searchQuery]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{seoConfig.titulo}</title>
          <meta name="description" content={seoConfig.descripcion} />
          <meta name="keywords" content={seoConfig.palabrasClave} />
          <link rel="canonical" href={seoConfig.canonical} />
          <meta property="og:title" content={seoConfig.openGraph.titulo} />
          <meta
            property="og:description"
            content={seoConfig.openGraph.descripcion}
          />
          <meta property="og:url" content={seoConfig.openGraph.url} />
          <meta property="og:type" content={seoConfig.openGraph.type} />
          <meta property="twitter:card" content={seoConfig.twitter.card} />
          <meta property="twitter:title" content={seoConfig.twitter.titulo} />
          <meta
            property="twitter:description"
            content={seoConfig.twitter.descripcion}
          />
        </Helmet>

        {/* handle path url case for /tienda and display StorePageLanding component if !productPage */}
        {location.pathname === '/tienda' && !productPage && (
          <>
            <HeaderShop
              selectedFilter={selectedFilter}
              onFilterChange={(filter: string) => setSelectedFilter(filter)}
              onSearch={(query: string) => setSearchQuery(query)}
              searchQuery={searchQuery}
              isLargeScreen={largeScreen}
              products={products}
              filterCategoriesFromSearch={filterCategoriesFromSearch}
              filtersFromSearch={filtersFromSearch}
            />
            <div className="w-10/12 h-10/12 m-auto">
              <StorePageLanding />
            </div>
          </>
        )}
        {!productPage && location.pathname !== '/tienda' && (
          <div className="bg-gray-100 font-poppins relative">
            <HeaderShop
              selectedFilter={selectedFilter}
              onFilterChange={(filter: string) => setSelectedFilter(filter)}
              onSearch={(query: string) => setSearchQuery(query)}
              searchQuery={searchQuery}
              isLargeScreen={largeScreen}
              products={products}
              filterCategoriesFromSearch={filterCategoriesFromSearch}
              filtersFromSearch={filtersFromSearch}
            />
            <div className="w-10/12 h-10/12 m-auto">
              <BodyShop
                selectedFilter={selectedFilter}
                onFilterChange={(filter: string) => setSelectedFilter(filter)}
                searchQuery={searchQuery}
                isLargeScreen={largeScreen}
                products={products}
                filterCategoriesFromSearch={filterCategoriesFromSearch}
                filtersFromSearch={filtersFromSearch}
                loading={loading}
              />
            </div>
          </div>
        )}
        {productPage && (
          <>
            <HeaderShop
              selectedFilter={selectedFilter}
              onFilterChange={(filter: string) => setSelectedFilter(filter)}
              onSearch={(query: string) => setSearchQuery(query)}
              searchQuery={searchQuery}
              isLargeScreen={largeScreen}
              products={products}
              filterCategoriesFromSearch={filterCategoriesFromSearch}
              filtersFromSearch={filtersFromSearch}
            />
            <ProductPage />
          </>
        )}
      </HelmetProvider>
    </>
  );
}
