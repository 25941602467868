import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';

export interface BreadcrumbWithCustomSeparatorProps {
  items: Array<{
    title: string;
    path: string;
    isCurrentPage?: boolean;
  }>;
}

export default function BreadcrumbWithCustomSeparator(
  props: BreadcrumbWithCustomSeparatorProps
) {
  const { items } = props;

  // FIX: handle cases where path has spaces, replace with dashes
  return (
    <Breadcrumb className="pt-2 pl-1">
      <BreadcrumbList>
        {items.map((item, index) => (
          <BreadcrumbItem key={item.path}>
            {item.isCurrentPage ? (
              <BreadcrumbPage>{item.title}</BreadcrumbPage>
            ) : (
              <BreadcrumbLink
                asChild
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = item.path.replace(/ /g, '-');
                }}
              >
                <Link to={item.path}>{item.title}</Link>
              </BreadcrumbLink>
            )}
            {index < items.length - 1 && <BreadcrumbSeparator />}
          </BreadcrumbItem>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
