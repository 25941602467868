import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import { CreditCard, Percent, Truck, Store } from 'lucide-react';

// Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Custom CSS
import '../index.css'; // Ensure you have this CSS file

export default function PromotionalBannerSlider() {
  const slides = [
    {
      icon: <CreditCard className="w-6 h-6 md:w-8 md:h-8" />,
      title: 'Hasta 3 cuotas sin interés',
      subtitle: '',
    },
    {
      icon: <Percent className="w-6 h-6 md:w-8 md:h-8" />,
      title: '10% OFF pagando con Transferencia Bancaria',
      subtitle: 'Exclusivo de Tienda Web',
    },
    {
      icon: <Truck className="w-6 h-6 md:w-8 md:h-8" />,
      title: 'Envíos a todo el país',
      subtitle: '',
    },
    {
      icon: <Store className="w-6 h-6 md:w-8 md:h-8" />,
      title: 'Retira tu pedido en nuestro local',
      subtitle: '¡GRATIS!',
    },
  ];

  return (
    <div className="bg-primary text-primary-foreground">
      <Swiper
        modules={[Pagination, A11y, Autoplay]}
        spaceBetween={0}
        slidesPerView={2}
        pagination={{
          clickable: true,
          bulletClass: 'swiper-pagination-bullet',
          bulletActiveClass: 'swiper-pagination-bullet-active',
        }} // Custom class names
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop={true}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
        className="py-2"
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            className="flex items-center justify-center h-32"
          >
            <div className="flex items-center h-full w-full px-4">
              <div className="mr-4 flex-shrink-0">{slide.icon}</div>
              <div className="flex flex-col justify-center text-left h-full">
                <h3 className="text-sm md:text-base font-semibold">
                  {slide.title}
                </h3>
                {slide.subtitle && (
                  <p className="mt-1 text-xs md:text-sm">{slide.subtitle}</p>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
