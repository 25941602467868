import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import { ChevronRight } from 'lucide-react';

export default function Body() {
  const trustedCompanies: Record<string, { default: string }> =
    import.meta.glob('/src/assets/empresas/*.jpeg', {
      eager: true,
    });
  const companyImages = Object.values(trustedCompanies).map(
    (module) => module.default
  );

  return (
    <main className="font-merriweather">
      <section
        id="top"
        className="relative h-[90vh] bg-cover bg-center flex items-center"
        style={{ backgroundImage: "url('/boceto.png')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-3xl">
            <h1 className="text-white text-4xl md:text-6xl font-poppins font-bold mb-4">
              SOLIDO HERRAJES
            </h1>
            <p className="text-white text-xl md:text-2xl">
              Donde la tradición es innovar.
            </p>
            <p className="text-white text-xl md:text-2xl mb-8">
              Simple, confiable y seguro.
            </p>
            <a href="/tienda">
              <Button
                size="lg"
                className="bg-red-600 hover:bg-red-700 text-white"
              >
                Explorar Catálogo <ChevronRight className="ml-2" />
              </Button>
            </a>
          </div>
        </div>
      </section>

      <section id="history & partners" className="py-20 bg-stone-100">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6 text-stone-800">
                Nuestra Historia
              </h2>
              <p className="text-lg text-stone-600 mb-4">
                El origen de Solido Herrajes es un enorme sueño hecho realidad:
                ser cada día, año tras año, confiable y estar en constante
                crecimiento.
              </p>
              <p className="text-lg text-stone-600 mb-6">
                Después de 16 años de mucho esfuerzo, reconocimiento y
                crecimiento, seguimos extendiendo el sueño de saber que nuestros
                clientes cada día confían más en que Solido Herrajes sabe y
                puede acompañarlos a concretar sus visiones.
              </p>
            </div>
            <div className="relative">
              <img
                src="/SH00009.jpg"
                alt="Local de Sólido Herrajes"
                className="rounded-lg shadow-xl"
              />
              <div className="absolute inset-0 opacity-20 rounded-lg"></div>
            </div>
          </div>
        </div>
      </section>

      <section id="trusted-companies" className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12 text-center text-stone-800">
            Empresas que Confían en Nosotros
          </h2>
          <div className="flex justify-center items-center mb-6">
            <p className="text-lg text-stone-600 text-center w-2/3">
              Reconocemos que cada una de las marcas que nos acompañan son parte
              de nuestra constante mejora en la atención a las necesidades de
              nuestros clientes.
            </p>
          </div>
          <Carousel
            opts={{
              align: 'start',
              loop: true,
            }}
            className="w-full max-w-sm mx-auto md:max-w-4xl"
          >
            <CarouselContent>
              {Object.values(companyImages).map((company, index) => (
                <CarouselItem
                  key={index}
                  className="basis-[65%] sm:basis-1/3 md:basis-1/4 lg:basis-1/5"
                >
                  <div className="p-1">
                    <Card>
                      <CardContent className="flex flex-col items-center justify-center p-6 min-h-40">
                        <img
                          src={company}
                          alt={company}
                          className="max-h-24 mb-4"
                          loading="lazy"
                        />
                      </CardContent>
                    </Card>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </div>
      </section>
    </main>
  );
}
