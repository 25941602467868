import { useState, useEffect, useRef } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import { type CarouselApi } from '@/components/ui/carousel';

type CarouselItemProps = {
  imageUrls: string[];
  imageName: string[] | undefined;
};

export function CarouselDemo({ imageUrls, imageName }: CarouselItemProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [api, setApi] = useState<CarouselApi | undefined>(undefined);
  const [imageLoaded, setImageLoaded] = useState(
    Array(imageUrls.length).fill(false)
  );
  const apiRef = useRef<CarouselApi | null>(null);

  useEffect(() => {
    if (api) {
      apiRef.current = api;
      api.on('select', () => {
        setCurrentIndex(api.selectedScrollSnap());
      });
    }
  }, [api]);

  // Preload all images to handle loading state properly.
  useEffect(() => {
    imageUrls.forEach((url, index) => {
      const img = new Image();
      img.src = url;
      img.onload = () => handleImagePreload(index);
    });
  }, [imageUrls]);

  const handleImagePreload = (index: number) => {
    setImageLoaded((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });
  };

  const handleThumbnailClick = (index: number) => {
    if (apiRef.current) {
      apiRef.current.scrollTo(index);
    }
  };

  return (
    <div className="space-y-4">
      <Carousel setApi={setApi}>
        <CarouselContent className="2xl:w-[800px] xl:w-[650px] lg:w-[450px]">
          {imageUrls.map((url, index) => (
            <CarouselItem
              key={index}
              className="flex justify-center items-center"
            >
              <Card className="border-0 shadow-none flex justify-center items-center w-[500px] h-[500px]">
                <CardContent className="p-0 relative w-full h-full overflow-hidden">
                  {!imageLoaded[index] && (
                    <img
                      src="/tube-spinner.svg"
                      alt="Loading spinner"
                      className="absolute inset-0 m-auto w-20 h-auto"
                    />
                  )}
                  <img
                    src={url}
                    alt={imageName ? imageName[index] : `Image ${index}`}
                    className={`absolute inset-0 w-full h-full object-contain transition-opacity duration-500 ${
                      imageLoaded[index] ? 'opacity-100' : 'opacity-0'
                    }`}
                    loading="lazy"
                    style={{ objectFit: 'contain' }} // Added inline style
                  />
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>

      <div className="flex justify-center space-x-3 flex-wrap gap-4">
        {imageUrls.map((url, index) => (
          <button
            key={index}
            className={`w-16 h-16 overflow-hidden ${
              index === currentIndex ? 'ring-2 ring-primary' : ''
            }`}
            onClick={() => handleThumbnailClick(index)}
          >
            <img
              src={url}
              alt={`Thumbnail ${imageName ? imageName[index] : ''}`}
              width={64}
              height={64}
              className="object-cover"
              loading="lazy"
            />
          </button>
        ))}
      </div>
    </div>
  );
}
