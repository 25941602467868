import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { DialogFooter } from '@/components/ui/dialog';
import { toast } from 'sonner';
import { useState } from 'react'; // Import useState for loading state management
import { Product } from '@/types';

const formSchema = z.object({
  name: z
    .string()
    .min(2, { message: 'El Nombre debe tener al menos 2 caracteres' }),
  mail: z
    .string()
    .email({ message: 'Por favor ingresa un correo electrónico válido' }),
  productToConsult: z.string().optional(),
  note: z.string().optional(),
});

interface ProductFormProps {
  productData: Product | null;
  setShowDialog: (show: boolean) => void;
}

export default function ProductForm({
  productData,
  setShowDialog,
}: ProductFormProps) {
  const [loading, setLoading] = useState(false); // Loading state
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      mail: '',
      productToConsult: productData?.attributes.name || '',
      note: '',
    },
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    setLoading(true); // Start loading state
    try {
      // Send the form data to your backend API
      const response = await fetch(
        'https://solido-herrajes-form-backend.fly.dev/submit',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json(); // Get JSON response

      if (!response.ok) {
        throw new Error(result.message || 'Error sending form data');
      }

      // Show success toast
      setShowDialog(false);
      toast.success('Formulario Enviado!', {
        description: `Gracias ${data.name}, tu consulta por ${data.productToConsult} se envió correctamente`,
        duration: 5000,
        className: 'bg-green-700 text-white',
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Uh oh, algo salió mal con el pedido, intentalo más tarde', {
        duration: 5000,
        className: 'bg-red-800 text-white',
      });
    } finally {
      setLoading(false); // Reset loading state
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        {/* Name Field */}
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Nombre</FormLabel>
              <FormControl>
                <Input placeholder="Tu Nombre" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {/* Email Field */}
        <FormField
          control={form.control}
          name="mail"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Correo Electrónico</FormLabel>
              <FormControl>
                <Input placeholder="correo@ejemplo.com" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {/* Product to Consult Field */}
        <FormField
          control={form.control}
          name="productToConsult"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Producto a consultar</FormLabel>
              <FormControl>
                <Input
                  placeholder="Producto a consultar"
                  {...field}
                  value={productData?.attributes.name || ''}
                  readOnly
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {/* Note Field */}
        <FormField
          control={form.control}
          name="note"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Nota (opcional)</FormLabel>
              <FormControl>
                <Input placeholder="Tu mensaje o consulta" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {/* Submit Button */}
        <DialogFooter>
          <Button type="submit" disabled={loading}>
            {loading ? 'Enviando...' : 'Enviar'}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
