import { useEffect } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import Home from './components/Home';
import Store from './pages/store/StorePage';
import Layout from './components/Layout';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate to="/tienda" replace />} />
          <Route path="/casa" element={<Home />} />
          <Route path="/tienda/*" element={<Store />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
