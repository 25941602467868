import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

interface RedirectSearchProps {
  searchQuery: string;
}

export default function RedirectSearch({ searchQuery }: RedirectSearchProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

  // Debounce search query updates
  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300);

    handler();
    return () => {
      handler.cancel();
    };
  }, [searchQuery]);

  useEffect(() => {
    const encodedQuery = encodeURIComponent(debouncedSearchQuery);
    const targetPath = `/tienda/busqueda?q=${encodedQuery}`;

    // Check if navigation is needed
    if (debouncedSearchQuery && location.search !== `?q=${encodedQuery}`) {
      navigate(targetPath, { replace: true });
    }
  }, [debouncedSearchQuery, location, navigate]);

  return null;
}
