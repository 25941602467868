import {
  DetailsBlock,
  ProductWithSubcategoryRelation,
  ProductWithCategoryRelation,
  ProductWithFilterRelation,
  ProductWithFilterCategoriesRelation,
} from '../types';

export const extractTextFromDetails = (
  detailsArray: DetailsBlock[]
): (string | undefined)[] => {
  return detailsArray.flatMap((block) => {
    if (block.type === 'heading') {
      return block.children[0]?.text;
    } else if (block.type === 'list') {
      return block.children.flatMap((item) =>
        item.children.map((child) => child.text)
      );
    } else if (block.type === 'paragraph') {
      return block.children.map((child) => child.text);
    } else {
      return [];
    }
  });
};

export const extractTextFromItems = (
  itemsFromBreadcrumb: {
    title: string;
    path: string;
    isCurrentPage?: boolean;
  }[]
): (string | undefined)[] => {
  return itemsFromBreadcrumb.flatMap((item) => {
    return item.title;
  });
};

export const extractTextFromSubcategory = (
  subcategory: ProductWithSubcategoryRelation | null
): string => {
  return subcategory?.attributes.name || 'N/A';
};

export const extractTextFromCategory = (
  category: ProductWithCategoryRelation | null
): string => {
  return category?.attributes.name || 'N/A';
};

export const extractTextFromFilters = (
  filters: ProductWithFilterRelation[] | null
): string[] => {
  if (filters && Array.isArray(filters)) {
    return filters.map((filter) => filter.attributes.name);
  } else {
    console.error('No filters found');
    return [];
  }
};

export const extractTextFromFilterCategories = (
  filterCategories: ProductWithFilterCategoriesRelation[] | null
): string[] => {
  if (filterCategories && Array.isArray(filterCategories)) {
    return filterCategories.map((filterCategory) => filterCategory.name);
  } else {
    console.error('No filter categories found');
    return [];
  }
};
