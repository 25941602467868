import { useState, useEffect } from 'react';
import { Product } from '@/types';

export const useBreadcrumb = (productData: Product | null) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<
    { title: string; path: string; isCurrentPage?: boolean }[]
  >([]);

  useEffect(() => {
    const fetchBreadcrumbItems = async () => {
      if (productData) {
        const { attributes } = productData;
        const { category, subcategory, item } = attributes || {};
        const categoryName = category?.data?.attributes?.name || 'N/A';
        const subcategoryName = subcategory?.data?.attributes?.name || 'N/A';
        const itemName = item?.data?.attributes?.name || 'N/A';
        const productName = attributes?.name || 'N/A';

        const breadcrumbItems = [
          {
            title: categoryName,
            path: `/tienda/${category.data.attributes.name}`,
            isCurrentPage: false,
          },
          {
            title: subcategoryName,
            path: `/tienda/${category.data.attributes.name}/${subcategory.data.attributes.name}`,
            isCurrentPage: false,
          },
          {
            title: itemName,
            path: `/tienda/${category.data.attributes.name}/${subcategory.data.attributes.name}/${item.data.attributes.name}`,
            isCurrentPage: false,
          },
          {
            title: productName,
            path: `/tienda/${category.data.attributes.name}/${subcategory.data.attributes.name}/${item.data.attributes.name}/${productData.attributes.name}`,
            isCurrentPage: true,
          },
        ];

        setBreadcrumbItems(breadcrumbItems);
      }
    };

    fetchBreadcrumbItems();
  }, [productData]);

  return breadcrumbItems;
};
