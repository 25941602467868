import {
  CardFeatured,
  CardContentFeatured,
} from '@/components/ui/cardFeatured';
import {
  CarouselFeatured,
  CarouselContentFeatured,
  CarouselItemFeatured,
  CarouselNextFeatured,
  CarouselPreviousFeatured,
} from '@/components/ui/carouselFeatured';
import { cn } from '@/lib/utils';

export function CarouselItemsShowcase() {
  // Store array of items
  const itemsShowcase = [
    {
      id: 1,
      name: 'Anafes',
      image: '/anafe.jpeg',
      url: 'cocina/electrococina',
    },
    {
      id: 2,
      name: 'Cafeteras',
      image: '/cafetera.jpeg',
      url: 'cocina/electrococina',
    },
    {
      id: 3,
      name: 'Campanas',
      image: '/campana.jpeg',
      url: 'cocina/electrococina',
    },
    {
      id: 4,
      name: 'Heladeras',
      image: '/heladera.jpeg',
      url: 'cocina/electrococina',
    },
    {
      id: 5,
      name: 'Hornos',
      image: '/horno.jpeg',
      url: 'cocina/electrococina',
    },
    {
      id: 6,
      name: 'Lavasecarropas',
      image: '/lavasecarropas.jpeg',
      url: 'hogar/electrohogar',
    },
    {
      id: 7,
      name: 'Lavavajillas',
      image: '/lavavajillas.jpeg',
      url: 'hogar/electrohogar',
    },
    {
      id: 8,
      name: 'Microondas',
      image: '/microondas.jpeg',
      url: 'cocina/electrococina',
    },
    {
      id: 9,
      name: 'Vinotecas',
      image: '/vinoteca.jpeg',
      url: 'cocina/electrococina',
    },
  ];

  // Handle item redirection
  function handleItemRedirect(itemName: string) {
    const formattedName = itemName.toLowerCase().replace(/\s+/g, '-');
    const itemUrl = itemsShowcase.find((item) => item.url)?.url || '';
    const url = `tienda/${itemUrl}/${formattedName}`;

    window.location.href = url;
  }

  return (
    <>
      {itemsShowcase.length !== 0 && (
        <>
          <h2 className="flex justify-start py-3 text-xl">Electrodomésticos</h2>
          <CarouselFeatured
            className="w-full overflow-hidden"
            opts={{
              loop: true,
              align: 'start',
            }}
          >
            <CarouselContentFeatured className="-ml-2">
              {itemsShowcase.map((item, id) => (
                <CarouselItemFeatured
                  key={id}
                  className="pl-1 basis-[65%] lg:basis-1/4 md:basis-1/3 sm:basis-1/2 hover:cursor-pointer"
                  onClick={() => handleItemRedirect(item.name)}
                >
                  <div className="p-1 mb-2 h-full">
                    <CardFeatured className="h-full">
                      <CardContentFeatured className="flex flex-col h-full p-6 hover:shadow-2xl transition-shadow duration-300">
                        <div className="flex flex-col h-full justify-between">
                          <div className="w-50 h-40">
                            <img
                              key={item.id}
                              src={item.image}
                              alt={item.name || 'Product Image'}
                              className="object-contain w-full h-full"
                            />
                          </div>
                          <div className="flex flex-col text-center mt-4">
                            <div>{item.name}</div>
                          </div>
                        </div>
                      </CardContentFeatured>
                    </CardFeatured>
                  </div>
                </CarouselItemFeatured>
              ))}
            </CarouselContentFeatured>

            {/* Adjust Previous and Next button styles */}
            <CarouselPreviousFeatured
              className={cn(
                'absolute h-12 w-12 rounded-full bg-red-200 left-3 top-1/2 -translate-y-1/2'
              )}
            />
            <CarouselNextFeatured
              className={cn(
                'absolute h-12 w-12 rounded-full bg-red-200 right-3 top-1/2 -translate-y-1/2'
              )}
            />
          </CarouselFeatured>
        </>
      )}
    </>
  );
}
