import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { SVGProps } from 'react';
import { JSX } from 'react/jsx-runtime';
import CloseIcon from './ui/CloseIcon';
import {
  Filter as FilterTypes,
  FilterCategory as FilterCategoryTypes,
  Product as ProductTypes,
} from '@/types';
import { matchPath } from 'react-router-dom';

interface FilterProps {
  filter: boolean;
  handleSetFilter: () => void;
  isLargeScreen: boolean;
  onFilterChange: (filter: string) => void;
  filtersFromSearch?: FilterTypes[];
  filtersCategoriesFromSearch?: FilterCategoryTypes[];
  products: ProductTypes[];
}

export default function Filter({
  filter,
  handleSetFilter,
  isLargeScreen,
  onFilterChange,
  filtersFromSearch,
  filtersCategoriesFromSearch,
  products,
}: FilterProps) {
  const [filters, setFilters] = useState<FilterTypes[]>([]);
  const [filterCategories, setFilterCategories] = useState<
    FilterCategoryTypes[]
  >([]);
  const [radioValue, setRadioValue] = useState('');
  const location = useLocation();

  function handleRadioChange(value: string) {
    setRadioValue(value);
    onFilterChange(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!isLargeScreen) {
      handleSetFilter();
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('q');

    if (searchQuery && filtersFromSearch && filtersCategoriesFromSearch) {
      // Update filters from search query if a search is performed
      setFilters(filtersFromSearch);
      setFilterCategories(filtersCategoriesFromSearch);
    } else if (!searchQuery && products.length > 0) {
      // Update filters from products if no search query
      const filtersFromProducts: FilterTypes[] = [];
      const filterCategoriesFromProducts: FilterCategoryTypes[] = [];

      products.forEach((product) => {
        const productFilters = product.attributes.filters?.data || [];
        const productFilterCategories =
          product.attributes.filter_categories?.data || [];

        productFilters.forEach((filter: FilterTypes) => {
          if (!filtersFromProducts.some((f) => f.id === filter.id)) {
            filtersFromProducts.push(filter);
          }
        });

        productFilterCategories.forEach((category: FilterCategoryTypes) => {
          if (!filterCategoriesFromProducts.some((c) => c.id === category.id)) {
            filterCategoriesFromProducts.push(category);
          }
        });
      });

      setFilters(filtersFromProducts);
      setFilterCategories(filterCategoriesFromProducts);
    }
  }, [
    location.search,
    filtersFromSearch,
    filtersCategoriesFromSearch,
    products,
  ]);

  if (location.pathname === '/tienda') {
    return null;
  } else if (matchPath('/tienda/producto/*', location.pathname)) {
    return null;
  }

  return (
    <>
      <div className={isLargeScreen ? 'flex bg-gray-100' : ''}>
        {!isLargeScreen && (
          <div className="p-2 lg:hidden">
            <Button
              onClick={handleSetFilter}
              variant="outline"
              className="flex items-center gap-2 px-4 py-2 rounded-md"
            >
              <FilterIcon className="w-5 h-5" />
              <span>Filtrar</span>
            </Button>
            {filter && (
              <>
                <div className="fixed top-0 right-0 w-full h-full bg-white flex justify-center items-center overflow-y-auto">
                  <div className="absolute top-0 flex flex-col mt-[150px]">
                    <CloseIcon
                      className="absolute right-0"
                      onClick={handleSetFilter}
                    />
                    <div className="h-dvh flex flex-col gap-3 py-6">
                      {filterCategories.map((filterCategory) => (
                        <div key={filterCategory.id}>
                          <h4 className="text-lg font-semibold py-3">
                            {filterCategory.attributes.name}
                          </h4>
                          <RadioGroup
                            value={radioValue}
                            onValueChange={handleRadioChange}
                          >
                            {filters
                              .filter((filter) => {
                                const filterCategoryData =
                                  filter.attributes.filter_category?.data;
                                return (
                                  filterCategoryData?.id === filterCategory.id
                                );
                              })
                              .map((filter) => (
                                <div
                                  key={filter.id}
                                  className="flex items-center space-x-2"
                                >
                                  <RadioGroupItem
                                    value={filter.attributes.name}
                                    id={filter.attributes.name}
                                    checked={
                                      radioValue === filter.attributes.name
                                    }
                                  />
                                  <Label
                                    htmlFor={filter.attributes.name}
                                    className="ml-2 p-1.5"
                                  >
                                    {filter.attributes.name}
                                  </Label>
                                </div>
                              ))}
                          </RadioGroup>
                        </div>
                      ))}
                      <div className="mt-4">
                        <h4 className="text-lg font-semibold py-3">Todos</h4>
                        <RadioGroup
                          value={radioValue}
                          onValueChange={handleRadioChange}
                        >
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem
                              value=""
                              id="none"
                              checked={radioValue === ''}
                            />
                            <Label htmlFor="none" className="ml-2 p-1.5">
                              Todos
                            </Label>
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {isLargeScreen && (
          <div className="hidden lg:block p-6 border-r">
            <h2 className="text-xl font-semibold mb-4 text-gray-800">
              Filtrar Búsqueda
            </h2>
            <div className="flex flex-col gap-3 mt-4 text-gray-600">
              {filterCategories.map((filterCategory) => (
                <div key={filterCategory.id}>
                  <h4 className="text-lg font-semibold py-3">
                    {filterCategory.attributes.name}
                  </h4>
                  <RadioGroup
                    value={radioValue}
                    onValueChange={handleRadioChange}
                  >
                    {filters
                      .filter((filter) => {
                        const filterCategoryData =
                          filter.attributes.filter_category?.data;
                        return filterCategoryData?.id === filterCategory.id;
                      })
                      .map((filter) => (
                        <div
                          key={filter.id}
                          className="flex items-center space-x-2"
                        >
                          <RadioGroupItem
                            value={filter.attributes.name}
                            id={filter.attributes.name}
                            checked={radioValue === filter.attributes.name}
                          />
                          <Label
                            htmlFor={filter.attributes.name}
                            className="ml-2 p-1.5"
                          >
                            {filter.attributes.name}
                          </Label>
                        </div>
                      ))}
                  </RadioGroup>
                </div>
              ))}
              <div className="mt-4">
                <h4 className="text-lg font-semibold py-3">Todos</h4>
                <RadioGroup
                  value={radioValue}
                  onValueChange={handleRadioChange}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem
                      value=""
                      id="none"
                      checked={radioValue === ''}
                    />
                    <Label htmlFor="none" className="ml-2 p-1.5">
                      Todos
                    </Label>
                  </div>
                </RadioGroup>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function FilterIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
    </svg>
  );
}
