export default function WhatsappButton() {
  return (
    <section>
      <a
        href="https://wa.me/5491128600745"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-4 right-4 z-50 bg-green-500 text-white p-4 rounded-full shadow-lg"
      >
        <img
          src="/whatsapp.png"
          alt="WhatsApp button"
          className="w-8 h-auto lg:w-12"
        />
      </a>
    </section>
  );
}
