import { Helmet, HelmetProvider } from 'react-helmet-async';
import { generarEtiquetasSeoMeta } from '@/site-config';
import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import BreadcrumbWithCustomSeparator from '@/components/ui/BreadcrumbWithCustomSeparator';
import { CarouselDemo } from '@/components/CarouselProducts';
import { CarouselRelatedProducts } from '@/components/CarouselRelatedProducts';
import { LiaShippingFastSolid } from 'react-icons/lia';
import { IoStorefrontOutline } from 'react-icons/io5';
import { Toaster } from 'sonner';
import ProductForm from '@/components/ProductForm';
import { useBreadcrumb } from '@/components/useBredcrumb';
import { Dialog, DialogContent, DialogOverlay } from '@/components/ui/dialog';
import { fetchProductsByName, getImageUrls } from '@/backend/strapi/apiService';
import { BlockChild, DetailsBlock, Product } from '@/types';
import {
  extractTextFromDetails,
  extractTextFromItems,
  extractTextFromSubcategory,
  extractTextFromCategory,
  extractTextFromFilters,
} from '@/utils/extractProductMeta';

export default function ProductPage() {
  const [productName, setProductName] = useState<string | null>(null);
  const [productData, setProductData] = useState<Product | null>(null);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [showDialog, setShowDialog] = useState(false);
  const breadcrumbItems = useBreadcrumb(productData);
  const slug = window.location.pathname.split('/').pop() || '';
  const seoMeta = generarEtiquetasSeoMeta();

  useEffect(() => {
    const fetchProductNameFromUrl = async () => {
      try {
        const url = new URL(window.location.href);
        const pathSegments = url.pathname.split('/');
        const productNameFromUrl = pathSegments.pop() || '';
        const translatedName = productNameFromUrl
          .split('-')
          .join(' ')
          .toUpperCase();
        setProductName(translatedName);

        const products = await fetchProductsByName(translatedName || '');
        if (products.length > 0) {
          const product = products[0];
          setProductData(product);

          const images = product.attributes.image?.data || [];
          const logo = product.attributes.logo?.data || null;
          const fetchedLogoUrl = logo?.attributes?.url || null;

          if (fetchedLogoUrl) {
            setLogoUrl(fetchedLogoUrl);
          }

          const fetchedImageUrls = await Promise.all(
            images.map(async (img) => {
              const urls = await getImageUrls(img);
              return urls.find((url) => url.includes('large')) || urls[0];
            })
          );
          setImageUrls(fetchedImageUrls);
        } else {
          setProductData(null);
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
        setProductData(null);
      }
    };

    fetchProductNameFromUrl();
  }, [productName]);

  if (!productName) {
    return (
      <div className="w-9/12 h-9/12 m-auto">
        <h1 className="text-3xl font-semibold text-center pt-10">
          El producto no se encuentra disponible
        </h1>
      </div>
    );
  }

  if (!productData) {
    return (
      <div className="w-9/12 h-9/12 m-auto">
        <h1 className="text-2xl text-gray-600 font-semibold text-center pt-10">
          Cargando producto...
        </h1>
      </div>
    );
  }

  const { attributes } = productData;
  const { name, model, details } = attributes || {};

  const renderDetails = (details: DetailsBlock[]) => {
    return details.map((block, index) => {
      switch (block.type) {
        case 'heading':
          return (
            <h2 key={index} className="text-3xl font-bold mt-4 mb-2">
              {block.children[0]?.text}
            </h2>
          );
        case 'list':
          return (
            <ul key={index} className="list-disc ml-5">
              {block.children.map((item, itemIndex) => (
                <li key={itemIndex}>
                  {item.children.map((child: BlockChild, childIndex: number) =>
                    child.bold ? (
                      <strong key={childIndex}>{child.text}</strong>
                    ) : (
                      <span key={childIndex}>{child.text}</span>
                    )
                  )}
                </li>
              ))}
            </ul>
          );
        case 'paragraph':
          return (
            <p key={index} className="mb-4">
              {block.children.map((child: BlockChild, childIndex: number) => (
                <span key={childIndex}>{child.text}</span>
              ))}
            </p>
          );
        default:
          return null;
      }
    });
  };

  return (
    <>
      <HelmetProvider>
        <Helmet prioritizeSeoTags>
          <title>
            {productData.attributes.name} | {seoMeta.titulo}
          </title>
          <meta
            name="description"
            content={extractTextFromDetails(details).join(' ')}
          />
          <meta
            name="keywords"
            content={`${name}, ${model}, ${seoMeta.titulo}`}
          />
          <meta name="author" content={seoMeta.titulo} />
          <meta name="robots" content="index, follow" />
          <link
            rel="canonical"
            href={`https://solidoherrajes.com/tienda/producto/${slug}`}
          />
          <meta property="og:producto" content={productData.attributes.name} />
          <meta
            property="og:producto descripcion"
            content={extractTextFromDetails(details).join(' ')}
          />
          <meta
            property="og:producto imagen"
            content={productData.attributes.image.data
              ?.map((img) => img.attributes.name)
              .join(', ')}
          />
          <meta
            property="og:producto items"
            content={extractTextFromItems(breadcrumbItems).join(' ')}
          />
          <meta
            property="og:producto subcategorias"
            content={extractTextFromSubcategory(
              productData.attributes.subcategory.data
            )}
          />
          <meta
            property="og:producto categorias"
            content={extractTextFromCategory(
              productData.attributes.category.data
            )}
          />
          <meta
            property="og:producto filtros"
            content={extractTextFromFilters(
              Array.isArray(productData.attributes.filters.data)
                ? productData.attributes.filters.data
                : [productData.attributes.filters.data]
            ).join(' ')}
          />
          <meta
            property="og:producto filtro_categorias"
            content={
              Array.isArray(productData.attributes.filter_categories.data)
                ? productData.attributes.filter_categories.data
                    .map((category) => category.attributes.name)
                    .join(' ')
                : [productData.attributes.filter_categories.data]
                    .map((category) => category.name)
                    .join(' ')
            }
          />
          <meta
            property="og:producto sku"
            content={productData.attributes.model}
          />
        </Helmet>
        <Toaster />
        <hr className="border-gray-200 border-1 " />
        {/* Product Information */}
        <div className="w-9/12 h-9/12 m-auto pt-4 flex flex-col lg:flex-row">
          <div className="flex flex-col gap-6 pb-8">
            <BreadcrumbWithCustomSeparator items={breadcrumbItems} />
            <CarouselDemo
              imageUrls={imageUrls}
              imageName={productData.attributes.image.data?.map(
                (img) => img.attributes.name
              )}
            />
          </div>
          <div className="lg:m-6 p-6 sm:pl-8 border-2 border-gray-300 rounded-md shadow-inner flex flex-col justify-between">
            <div>
              {logoUrl && (
                <img
                  src={logoUrl}
                  alt={productData.attributes.logo.data?.attributes.name}
                  loading="lazy"
                  className="w-40"
                />
              )}
              <h1 className="font-semibold text-2xl pt-10">{name}</h1>
              <h2 className="pt-4">
                <span className="text-red-900 font-bold">SKU:</span> {model}
              </h2>
              <div className="pt-10 flex gap-2">
                <LiaShippingFastSolid className="mt-1" /> Envios a todo el país
              </div>
              <div className="pt-5 flex gap-2 text-green-600">
                <IoStorefrontOutline className="mt-1 text-green-600" /> Retiro
                GRATIS en sucursal
              </div>
              <p className="pb-6 lg:pb-0 poppins pt-[3rem] text-gray-600">
                Por favor, completá el formulario para que podamos contactarte
                lo antes posible y brindarte nuestra ayuda
              </p>
            </div>
            <div className="pb-5 flex justify-center items-center">
              <Button className="w-full" onClick={() => setShowDialog(true)}>
                Consultar
              </Button>
            </div>
          </div>
        </div>

        {/* Product Details */}
        <div className="w-9/12 h-9/12 m-auto pt-4">
          {details ? renderDetails(details) : <p>No details available.</p>}
        </div>

        <div className="w-9/12 h-9/12 m-auto pt-6">
          <div className="pt-1 flex flex-col justify-center cursor-pointer">
            <CarouselRelatedProducts product={productData} />
          </div>
        </div>

        {/* Form Dialog */}
        <Dialog open={showDialog} onOpenChange={setShowDialog}>
          <DialogOverlay />
          <DialogContent>
            <ProductForm
              productData={productData}
              setShowDialog={setShowDialog}
            />
          </DialogContent>
        </Dialog>
      </HelmetProvider>
    </>
  );
}
