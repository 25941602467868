import { Input } from './ui/input';
import { Button } from './ui/button';
import SearchIcon from './ui/SearchIcon';
import { useState, useRef } from 'react';
import RedirectSearch from './RedirectSearch';

interface SearchBarProps {
  onSearch: (query: string) => void;
  searchQuery: string;
}

export default function SearchBar({ onSearch, searchQuery }: SearchBarProps) {
  const [input, setInput] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInput(event.target.value);
  }

  function handleSearch() {
    onSearch(input);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      handleSearch();
    }
  }

  return (
    <div className="relative flex items-center">
      <RedirectSearch searchQuery={searchQuery} />
      <Input
        ref={inputRef}
        type="text"
        placeholder="Buscar"
        className="pl-5 pr-5 py-2.5 flex-grow w-full rounded-md bg-gray-200 text-sm focus:outline-none focus:ring-2 focus:ring-accent focus:border-transparent"
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        value={input}
      />
      <Button
        variant="outline"
        size="icon"
        className="ml-2"
        onClick={handleSearch}
      >
        <SearchIcon className="h-5 w-5 flex" />
        <span className="sr-only">Search</span>
      </Button>
    </div>
  );
}
