import { useEffect, useState } from 'react';
import { CardRelated, CardContentRelated } from '@/components/ui/cardRelated';
import {
  CarouselRelated,
  CarouselContentRelated,
  CarouselItemRelated,
  CarouselNextRelated,
  CarouselPreviousRelated,
} from '@/components/ui/carouselRelated';
import { Product } from '@/types';
import { fetchProductsByItem } from '@/backend/strapi/apiService';
import { cn } from '@/lib/utils';

interface CarouselRelatedProductsProps {
  product: Product;
}

export function CarouselRelatedProducts({
  product,
}: CarouselRelatedProductsProps) {
  const [products, setProducts] = useState<Product[]>([]);
  const productItem = product.attributes.item.data.attributes.name;

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const relatedProducts = await fetchProductsByItem(productItem);
        // Remove the current product from the related products
        setProducts(relatedProducts.filter((p) => p.id !== product.id));
      } catch (error) {
        console.error('Error fetching related products:', error);
        setProducts([]);
      }
    };

    fetchRelatedProducts();
  }, [product.id, productItem]);

  function handleProductClick(name: string) {
    const slug = name.toLowerCase().replace(/\s+/g, '-');
    window.location.href = `/tienda/producto/${slug}`;
  }

  return (
    <>
      {products.length !== 0 && (
        <p className="text-left mb-2 text-xl">Productos relacionados</p>
      )}
      {products.length !== 0 && (
        <CarouselRelated
          className="w-full overflow-hidden cursor-pointer"
          opts={{
            loop: true,
            align: 'start',
          }}
        >
          <CarouselContentRelated className="-ml-2">
            {products.map((product, id) => (
              <CarouselItemRelated
                key={id}
                className="pl-1 basis-[65%] lg:basis-1/4 md:basis-1/3 sm:basis-1/2 hover:cursor-pointer"
                onClick={() => handleProductClick(product.attributes.name)}
              >
                <div className="p-1 mb-2 h-full">
                  <CardRelated className="h-full">
                    <CardContentRelated className="flex flex-col h-full p-6">
                      {/* Use justify-between and ensure the container takes full height */}
                      <div className="flex flex-col h-full justify-between">
                        {/* Image Container */}
                        <div className="w-50 h-40">
                          {product.imageUrls && product.imageUrls.length > 0 ? (
                            <img
                              className="object-contain w-full h-full"
                              src={
                                product.imageUrls.find((url) =>
                                  url.includes('small')
                                ) || product.imageUrls[0]
                              }
                              alt={product.attributes.name}
                              loading="lazy"
                            />
                          ) : (
                            <p>Sin imagen</p>
                          )}
                        </div>

                        {/* Name, SKU, and Button */}
                        <div className="flex flex-col text-center mt-4">
                          <div>{product.attributes.name}</div>
                          <p className="mt-3">
                            <span className="text-red-900 font-bold">
                              SKU:{' '}
                            </span>
                            {product.attributes.model}
                          </p>

                          <div className="w-22 h-10 bg-red-900 flex justify-center items-center mt-4 mb-4 rounded-sm">
                            <p className="text-white">Consultar</p>
                          </div>
                        </div>
                      </div>
                    </CardContentRelated>
                  </CardRelated>
                </div>
              </CarouselItemRelated>
            ))}
          </CarouselContentRelated>
          <CarouselPreviousRelated
            className={cn(
              'absolute h-12 w-12 rounded-full bg-red-200 left-3 top-1/3 -translate-y-1/2'
            )}
          />
          <CarouselNextRelated
            className={cn(
              'absolute h-12 w-12 rounded-full bg-red-200 right-3 top-1/3 -translate-y-1/2'
            )}
          />
        </CarouselRelated>
      )}
    </>
  );
}
