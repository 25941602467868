import {
  CardFeatured,
  CardContentFeatured,
} from '@/components/ui/cardFeatured';
import {
  CarouselFeatured,
  CarouselContentFeatured,
  CarouselItemFeatured,
  CarouselNextFeatured,
  CarouselPreviousFeatured,
} from '@/components/ui/carouselFeatured';
import { Product } from '@/types';

interface CarouselFeaturedProductsProps {
  products: Product[];
}

export function CarouselFeaturedProducts({
  products,
}: CarouselFeaturedProductsProps) {
  function handleProductClick(name: string) {
    const slug = name.toLowerCase().replace(/\s+/g, '-');
    window.location.href = `/tienda/producto/${slug}`;
  }

  return (
    <>
      {products.length !== 0 && (
        <CarouselFeatured
          className="w-full overflow-hidden"
          opts={{
            loop: true,
            align: 'start',
          }}
        >
          <CarouselContentFeatured className="-ml-2">
            {products.map((product, id) => (
              <CarouselItemFeatured
                key={id}
                className="pl-1 basis-[65%] lg:basis-1/4 md:basis-1/3 sm:basis-1/2 hover:cursor-pointer"
                onClick={() => handleProductClick(product.attributes.name)}
              >
                <div className="p-1 mb-2 h-full">
                  <CardFeatured className="h-full">
                    <CardContentFeatured className="flex flex-col h-full p-6 hover:shadow-2xl transition-shadow duration-300">
                      {/* Use justify-between and ensure the container takes full height */}
                      <div className="flex flex-col h-full justify-between">
                        {/* Image Container */}
                        <div className="w-50 h-40">
                          {product.attributes.image?.data?.[0]?.attributes ? (
                            <img
                              key={product.id}
                              src={
                                product.attributes.image?.data?.[0].attributes
                                  .formats?.small?.url ||
                                product.attributes.image?.data?.[0].attributes
                                  .url ||
                                product.imageUrls[0]
                              }
                              alt={
                                product.attributes.image?.data?.[0].attributes
                                  .name || 'Product Image'
                              }
                              className="object-contain w-full h-full"
                            />
                          ) : (
                            <div className="w-16 h-16 bg-gray-200 flex items-center justify-center">
                              <span>No Image</span>
                            </div>
                          )}
                        </div>

                        {/* Name and Button */}
                        <div className="flex flex-col text-center mt-4">
                          <div>{product.attributes.name}</div>
                          <p className="mt-3">
                            <span className="text-red-900 font-bold">
                              SKU:{' '}
                            </span>
                            {product.attributes.model}
                          </p>
                          <div className="w-22 h-10 bg-red-900 flex justify-center items-center  mt-4 mb-4 rounded-sm">
                            <p className="text-white">Consultar</p>
                          </div>
                        </div>
                      </div>
                    </CardContentFeatured>
                  </CardFeatured>
                </div>
              </CarouselItemFeatured>
            ))}
          </CarouselContentFeatured>
          <CarouselPreviousFeatured />
          <CarouselNextFeatured />
        </CarouselFeatured>
      )}
    </>
  );
}
