import { Home } from '@geist-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    setIsSticky(location.pathname === '/casa');
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleMobileLinkClick = () => {
    setIsMenuOpen(false);
  };

  const handleMenuToggle = () => {
    setTimeout(() => {
      setIsMenuOpen(!isMenuOpen);
    }, 0);
  };

  const handleScrollLinkClick = (to: string) => {
    if (location.pathname !== '/casa') {
      navigate('/casa', { replace: true });
      setTimeout(() => {
        scroller.scrollTo(to, {
          smooth: true,
          duration: 400,
          offset: -headerHeight,
        });
        setIsMenuOpen(false);
      }, 100); // Adjust timing as needed
    } else {
      scroller.scrollTo(to, {
        smooth: true,
        duration: 400,
        offset: -headerHeight,
      });
      setIsMenuOpen(false);
    }
  };

  return (
    <header
      ref={headerRef}
      style={{
        position: isSticky ? 'sticky' : 'relative',
        top: 0,
      }}
      className="bg-white flex justify-between items-center py-2 px-6 shadow-md font-merriweather sticky z-20 top-0 cursor-pointer md:px-32"
    >
      <div className="flex items-center">
        <a href="/tienda">
          <img
            src="/solidoherrajes.jpeg"
            alt="Solido Herrajes Logo"
            className="mt-3 h-16"
            loading="lazy"
          />
        </a>
      </div>
      <div className="hidden lg:flex space-x-8 text-lg">
        <button
          onClick={() => handleScrollLinkClick('top')}
          className="hover:text-gray-700 hover:underline"
        >
          Sobre Nosotros
        </button>
        <a href="/tienda" className="hover:text-gray-700 hover:underline">
          Comprar
        </a>
        <button
          onClick={() => handleScrollLinkClick('history & partners')}
          className="hover:text-gray-700 hover:underline"
        >
          Historia & Socios
        </button>
        <button
          onClick={() => handleScrollLinkClick('footer')}
          className="hover:text-gray-700 hover:underline"
        >
          Donde Encontrarnos
        </button>
      </div>
      <div className="relative lg:hidden">
        <button
          ref={buttonRef}
          className="flex items-center space-x-2 px-4 py-2 rounded-md hover:bg-gray-100"
          onClick={handleMenuToggle}
        >
          <Home />
          <span>Menu</span>
        </button>
        {isMenuOpen && (
          <nav
            ref={menuRef}
            className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-[51]"
          >
            <div className="py-1">
              <button
                onClick={() => handleScrollLinkClick('top')}
                className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left"
              >
                Sobre Nosotros
              </button>
              <a
                href="/tienda"
                onClick={handleMobileLinkClick}
                className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left"
              >
                Comprar
              </a>
              <button
                onClick={() => handleScrollLinkClick('history & partners')}
                className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left"
              >
                Historia y Socios
              </button>
              <button
                onClick={() => handleScrollLinkClick('footer')}
                className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left"
              >
                Donde Encontrarnos
              </button>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}
