import React from 'react';
import { Product } from '@/types';

interface ProductsProps {
  products: Product[];
}

const Products: React.FC<ProductsProps> = ({ products }) => {
  const generateProductSlug = (name: string) => {
    return name.toLowerCase().replace(/\s+/g, '-');
  };

  return (
    <div>
      <ul className="pt-4 flex flex-wrap justify-center sm:justify-start">
        {products.map((product) => {
          const imageUrls = product.imageUrls;
          const productName = product.attributes.name;
          const slug = generateProductSlug(productName);

          return (
            <li
              key={product.id}
              className="flex justify-center items-center m-1 bg-white rounded text-center shadow-inner hover:shadow-2xl transition-shadow duration-300"
              style={{
                border: '2px solid rgb(238, 238, 238)',
              }}
            >
              <a
                href={`/tienda/producto/${slug}`}
                className="flex flex-col items-center w-60 h-full"
                style={{ textDecoration: 'none' }}
              >
                {imageUrls ? (
                  <img
                    src={
                      imageUrls.find((url) => url.includes('small')) ||
                      imageUrls[0]
                    }
                    alt={product.attributes.name}
                    className="h-40 p-4 object-contain"
                    loading="lazy"
                  />
                ) : (
                  <div className="w-16 h-16 bg-gray-200 flex items-center justify-center">
                    <span>No Image</span>
                  </div>
                )}
                <div className="p-4 flex flex-col h-full flex-grow">
                  <h3 className="text-lg text-gray-700">
                    {product.attributes.name}
                  </h3>
                  <div className="flex pt-2 justify-center">
                    <p className="text-red-700 font-semibold pr-2">SKU: </p>
                    <p className="text-gray-500">{product.attributes.model}</p>
                  </div>
                  <div className="mt-auto">
                    <div className="w-22 h-10 bg-red-900 flex justify-center items-center mx-auto mt-2 rounded-sm">
                      <p className="text-white">Consultar</p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Products;
