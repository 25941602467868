// Configuración y constantes del sitio
export const SITE_CONFIG = {
  // Información básica de la empresa
  nombre: 'Solido Herrajes',
  lema: 'Herrajes y Electrodomésticos',
  descripcion:
    'Somos Solido Herrajes, nuestro rol en la industria de la construcción es brindar todos los artículos que sean funcionales de alto rendimiento, así como también de terminación, siendo también capaces de proveer al sector de amoblamientos, herreria, cerrajeria realizando ventas por mayor y menor.',

  // Información de contacto
  contacto: {
    telefono: {
      numero: '+54 11 2860-0745',
      formato: '+5491128600745',
      enlaceWhatsapp: 'https://wa.me/5491128600745',
    },
    correo: 'info@solidoherrajes.com',
    instagram: {
      usuario: '@solidoherrajes',
      enlace: 'https://www.instagram.com/solidoherrajes/?hl=es-la',
    },
  },

  // Información de ubicación
  ubicacion: {
    direccion: 'Maipú 1599',
    ciudad: 'Ingeniero Maschwitz',
    provincia: 'Buenos Aires',
    codigoPostal: 'B1623CFR',
    pais: 'Argentina',
    coordenadas: {
      latitud: -34.390052,
      longitud: -58.741003,
    },
    enlaceGoogleMaps:
      'https://www.google.com/maps?ll=-34.390052,-58.741003&z=14&t=m&hl=en&gl=US&mapclient=embed&q=Maip%C3%BA+1599+B1623CFR+Ingeniero+Maschwitz+Provincia+de+Buenos+Aires',
  },

  // Horario de atención
  horario: {
    lunesAViernes: 'Lunes - Viernes: 8:00 - 13:00, 14:30 - 18:00',
    sabado: 'Sábados: 9:00 - 13:00',
    domingo: 'Domingos: Cerrado',
  },

  // Enlaces del sitio web
  enlaces: {
    inicio: 'https://www.solidoherrajes.com',
    sobreNosotros: 'https://www.solidoherrajes.com/casa',
    contacto: 'https://wa.me/5491128600745',
    landing: 'https://www.solidoherrajes.com/tienda',
    categoria: {
      cocina: 'https://www.solidoherrajes.com/tienda/cocina',
      hogar: 'https://www.solidoherrajes.com/tienda/hogar',
    },
    subcategoria: {
      electrococina:
        'https://www.solidoherrajes.com/tienda/cocina/electrococina',
      electrohogar: 'https://www.solidoherrajes.com/tienda/hogar/electrohogar',
    },
  },

  // Palabras clave para SEO
  palabrasClave: [
    'Solido Herrajes',
    'Herrajes Maschwitz',
    'Electrodomésticos Maschwitz',
    'Solido',
    'Herrajes',
    'Electro',
    'Electrodomésticos',
    'Herrajes para cocina',
    'Herrajes para muebles',
    'Herrajes para puertas',
    'Accesorios para muebles modernos',
    'Bosch',
    'Electro bosch',
    'Cocina',
    'Hogar',
    'Construcción',
    'Accesorios',
    'Tienda de electrodomésticos',
    'Muebles',
    'Puertas',
    'Heladeras',
    'Hornos',
    'Anafes',
    'Placares',
    'Final de obra',
    'Vestidores',
    'Cajones',
    'Cajoneras',
    'Cerraduras',
    'Placas interioristas',
    'Interiorismo',
    'Escobar Herrajes',
    'Herrarjes Escobar',
    'Comprar Herrajes',
    'Venta de electrodomésticos',
    'Herrajes mayorista',
    'Electrodomésticos precios',
  ],

  // Redes Sociales
  redesSociales: {
    facebook: '', // Añadir si es aplicable
    twitter: '', // Añadir si es aplicable
    linkedin: '', // Añadir si es aplicable
  },
};

// Función para generar etiquetas meta de SEO
export function generarEtiquetasSeoMeta(configEspecificoPagina = {}) {
  const configPorDefecto = {
    titulo: `${SITE_CONFIG.nombre} - ${SITE_CONFIG.lema}`,
    descripcion: SITE_CONFIG.descripcion,
    palabrasClave: SITE_CONFIG.palabrasClave.join(', '),
  };

  const config = { ...configPorDefecto, ...configEspecificoPagina };

  return {
    titulo: config.titulo,
    descripcion: config.descripcion,
    palabrasClave: config.palabrasClave,
    canonical: SITE_CONFIG.enlaces.inicio,
    ubicacion: SITE_CONFIG.ubicacion,
    openGraph: {
      type: 'business.business',
      titulo: config.titulo,
      descripcion: config.descripcion,
      url: SITE_CONFIG.enlaces.inicio,
      nombreSitio: SITE_CONFIG.nombre,
    },
    twitter: {
      card: 'summary_large_image',
      titulo: config.titulo,
      descripcion: config.descripcion,
    },
  };
}

// Función para generar datos estructurados (JSON-LD)
export function generarDatosEstructurados() {
  return {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    nombre: SITE_CONFIG.nombre,
    imagen: `${SITE_CONFIG.enlaces.inicio}/logo.png`,
    url: SITE_CONFIG.enlaces.inicio,
    telefono: SITE_CONFIG.contacto.telefono.numero,
    direccion: {
      '@type': 'PostalAddress',
      calle: SITE_CONFIG.ubicacion.direccion,
      localidad: SITE_CONFIG.ubicacion.ciudad,
      region: SITE_CONFIG.ubicacion.provincia,
      codigoPostal: SITE_CONFIG.ubicacion.codigoPostal,
      pais: SITE_CONFIG.ubicacion.pais,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitud: SITE_CONFIG.ubicacion.coordenadas.latitud,
      longitud: SITE_CONFIG.ubicacion.coordenadas.longitud,
    },
    horarioAtencion: {
      '@type': 'OpeningHoursSpecification',
      diaSemana: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'],
      abre: '08:00',
      cierra: '18:00',
    },
    puntoContacto: {
      '@type': 'ContactPoint',
      telefono: SITE_CONFIG.contacto.telefono.numero,
      tipoContacto: 'servicio al cliente',
    },
  };
}
